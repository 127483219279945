<template>
  <div class="ui-settings">
    <app-navigation :title="$t('widget.widgets')" actionButtonIcon="fa fa-upload"
      :actionButtonDesc="$t('widget.save-widgets')" @action="onSaveWidgets"></app-navigation>

    <el-row class="header" style="margin-bottom: 10px; font-weight: bold">
      <el-col :span="2">
        {{ $t('widget.enable') }}
      </el-col>
      <el-col v-if="!versionCheck" :span="2">
        {{ $t('widget.icon') }}
      </el-col>
      <el-col :span="10">
        {{ $t('widget.title') }}
      </el-col>
      <el-col :span="8">
        {{ $t('widget.type') }}
      </el-col>
      <el-col :span="2">
        {{ $t('widget.actions') }}
      </el-col>
    </el-row>

    <Container @drop="onDrop" drag-handle-selector=".column-drag-handle">
      <Draggable v-for="item in items" :key="item.id">
        <div class="draggable-item">
          <el-col :span="2">
            <el-checkbox v-model="item.enable" style="margin-left: 10px" />
          </el-col>
          <el-col v-if="!versionCheck" :span="2"
            style="display: flex; align-items: center; margin-top: 10px; margin-bottom: 10px">
            <img @error="onImageError" :src="'/assets/icons/' + item.icon + '.png'" class="icon-contact">
          </el-col>
          <el-col :span="10">
            {{ item.title }}
          </el-col>
          <el-col :span="8">
            {{ getType(item.id) }}
          </el-col>
          <el-col :span="2">

            <span v-if="item.enable && item.id != 8" class="column-drag-handle"
              style="float:right; padding:0 20px;">&#x2630;</span>
            <span @click="onClickEditWidget(item)"><i class="far fa-edit"></i></span>
          </el-col>

        </div>
      </Draggable>
    </Container>


    <WidgetDialog ref="widgetDialog" :visible="newDialogVisible" @saveWidget="saveWidget"
      @cancelWidgetDialog="newDialogVisible = false" :widget="activeWidget"></WidgetDialog>
  </div>
</template>

<script>

import { Container, Draggable } from "vue-smooth-dnd"
import Navigation from '@/components/Navigation.vue';
import WidgetDialog from './WidgetDialog'
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "Widgets",
  components: {
    Container, Draggable, appNavigation: Navigation, WidgetDialog
  },
  data: function () {
    return {
      items: [],
      loading: false,
      newDialogVisible: false,
      activeWidget: {},
    }
  },
  computed: {
    versionCheck: function () {
      return this.$store.getters.coreosVersion.startsWith('3.2.140')
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    onImageError: function (e) {
      e.target.src = "/assets/icons/ic_home1.png";
    },

    onClickEditWidget(widget) {
      this.activeWidget = widget
      this.newDialogVisible = true
    },

    saveWidget(widget) {
      this.newDialogVisible = false
      this.items = this.items.map(item => {
        return item.id == widget.id ? widget : item
      })

      this.onSaveWidgets()
    },

    getType: function (id) {
      switch (id) {
        case 1: return "Scene"
        case 2: return "Device"
        case 3: return "Security"
        case 4: return "Contact"
        case 5: return "Shortcut"
        case 6: return "Room"
        case 7: return "RSS"
        case 8: return "Info"
      }
    },

    onDrop(dropResult) {
      this.items = this.applyDrag(this.items, dropResult)
      console.log(this.items)
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr



      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result.map((item, index) => {
        return { ...item, order: index }
      })
    },

    onSaveWidgets() {
      this.$store.dispatch("editWidgets", this.items).then(response => {
        this.loading = false
        this.$message({
          type: "success",
          message: this.$t(response.message)
        })

        //this.$router.push({name: "Accessories"})
      }).catch(error => {
        this.loading = false
        this.$message({
          type: "error",
          message: this.$t(error)
        })
      })
    },

    async fetchData() {
      try {
        await this.$store.dispatch("getWidgets");
        this.items = [...this.$store.getters.widgets];
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}
</script>

<style scoped>
@import "../../style/ui-settings.css";

.column-drag-handle {
  cursor: move;
  padding: 5px;
}

.icon-contact {
  filter: brightness(0.45);
  width: 30px;
  height: 30px;
}

span i {
  padding: 5px;
  cursor: pointer;
}

span i:hover {
  background-color: #00BFD7;
  border-radius: 5px;
  color: white;
}

span i:active {
  box-shadow: 0px 0px 10px #00BFD7;
  border-radius: 5px;
}</style>