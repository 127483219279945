var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.$t("widget.update-widget"), visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: function($event) {
          return _vm.$emit("cancelWidgetDialog")
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.widget } },
        [
          !_vm.versionCheck
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("widget.icon"),
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.widget.id != 7 && _vm.widget.id != 8
                        ? _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t("widget.select-icon")
                              },
                              model: {
                                value: _vm.widget.icon,
                                callback: function($$v) {
                                  _vm.$set(_vm.widget, "icon", $$v)
                                },
                                expression: "widget.icon"
                              }
                            },
                            _vm._l(_vm.icons, function(icon, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    label: icon.title,
                                    value: icon.value
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon-select-layout" },
                                    [
                                      _c("img", {
                                        staticClass: "icon-select",
                                        attrs: {
                                          src:
                                            "/assets/icons/" +
                                            icon.value +
                                            ".png"
                                        },
                                        on: { error: _vm.onImageError }
                                      }),
                                      _vm._v(_vm._s(icon.title))
                                    ]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", [
                      _c("img", {
                        staticClass: "icon-contact",
                        attrs: {
                          src: "/assets/icons/" + _vm.widget.icon + ".png"
                        },
                        on: { error: _vm.onImageError }
                      })
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("widget.widget-title"),
                "label-width": "120px",
                prop: "title",
                rules: { required: true }
              }
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.saveWidget($event)
                  }
                },
                model: {
                  value: _vm.widget.title,
                  callback: function($$v) {
                    _vm.$set(_vm.widget, "title", $$v)
                  },
                  expression: "widget.title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("cancelWidgetDialog")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("widget.cancel")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveWidget } },
            [_vm._v(_vm._s(_vm.$t("widget.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }