import { render, staticRenderFns } from "./WidgetDialog.vue?vue&type=template&id=32374dbd&scoped=true&"
import script from "./WidgetDialog.vue?vue&type=script&lang=js&"
export * from "./WidgetDialog.vue?vue&type=script&lang=js&"
import style0 from "./WidgetDialog.vue?vue&type=style&index=0&id=32374dbd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32374dbd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\sinan\\Desktop\\Core Projects\\CoreOS-V4-develop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32374dbd')) {
      api.createRecord('32374dbd', component.options)
    } else {
      api.reload('32374dbd', component.options)
    }
    module.hot.accept("./WidgetDialog.vue?vue&type=template&id=32374dbd&scoped=true&", function () {
      api.rerender('32374dbd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ui/WidgetDialog.vue"
export default component.exports