<template>
  <el-dialog :title="$t('widget.update-widget')" :visible.sync="visible" @closed="$emit('cancelWidgetDialog')">

    <el-form ref="form" :model="widget">
      <el-form-item v-if="!versionCheck" :label="$t('widget.icon')" label-width="120px">

        <el-col :span="12">
          <el-select v-if="widget.id != 7 && widget.id != 8" v-model="widget.icon" filterable :placeholder="$t('widget.select-icon')">
            <el-option v-for="(icon, index) in icons" :key="index" :label="icon.title" :value="icon.value">
              <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + widget.icon + '.png'" class="icon-contact">
              </span>
        </el-col>

      </el-form-item>

      <el-form-item :label="$t('widget.widget-title')" label-width="120px" prop="title" :rules="{required: true}">
        <el-input v-model="widget.title" autocomplete="off" @keyup.enter.native="saveWidget"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
    <el-button @click="$emit('cancelWidgetDialog')">{{ $t('widget.cancel') }}</el-button>
    <el-button type="primary" @click="saveWidget">{{ $t('widget.confirm') }}</el-button>
  </span>
  </el-dialog>
</template>

<script>
import widget from '@/store/modules/widget';

  export default {
    name: "WidgetDialog",

    props: {
      visible: {
        type: Boolean,
        required: true,
        default: false
      },

      widget: {}
    },

    data: function(){
      return {
        icons: [
          { title: this.$t('widget.icons[0]'), value: "widget_home" },
          { title: this.$t('widget.icons[1]'), value: "widget_scene" },
          { title: this.$t('widget.icons[2]'), value: "widget_intercom" },
          { title: this.$t('widget.icons[3]'), value: "widget_security" },
          //{ title: this.$t('widget.icons[4]'), value: "widget_home reception" },
          { title: this.$t('widget.icons[5]'), value: "widget_devices" },
          //{ title: this.$t('widget.icons[6]'), value: "widget_home bussiness" },
          //{ title: this.$t('widget.icons[7]'), value: "widget_home person" },
          { title: this.$t('widget.icons[8]'), value: "widget_favorite" },
        ]
      }
    },
    computed: {
      versionCheck: function () {
        return this.$store.getters.coreosVersion.startsWith('3.2.140')
      }
    },
    methods: {
      onImageError: function(e){
        e.target.src = "/assets/icons/ic_home1.png";
      },

      saveWidget(){
        if(this.widget.id == 7 || this.widget.id == 8){
          this.widget.icon = 'widget_home'
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$emit('saveWidget', this.widget)
          }
        })
      }
    },
  }
</script>

<style scoped>
  .icon-select-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-select {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }
</style>