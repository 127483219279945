var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-settings" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("widget.widgets"),
          actionButtonIcon: "fa fa-upload",
          actionButtonDesc: _vm.$t("widget.save-widgets")
        },
        on: { action: _vm.onSaveWidgets }
      }),
      _c(
        "el-row",
        {
          staticClass: "header",
          staticStyle: { "margin-bottom": "10px", "font-weight": "bold" }
        },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("widget.enable")) + "\n    ")
          ]),
          !_vm.versionCheck
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v("\n      " + _vm._s(_vm.$t("widget.icon")) + "\n    ")
              ])
            : _vm._e(),
          _c("el-col", { attrs: { span: 10 } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("widget.title")) + "\n    ")
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("widget.type")) + "\n    ")
          ]),
          _c("el-col", { attrs: { span: 2 } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("widget.actions")) + "\n    ")
          ])
        ],
        1
      ),
      _c(
        "Container",
        {
          attrs: { "drag-handle-selector": ".column-drag-handle" },
          on: { drop: _vm.onDrop }
        },
        _vm._l(_vm.items, function(item) {
          return _c("Draggable", { key: item.id }, [
            _c(
              "div",
              { staticClass: "draggable-item" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c("el-checkbox", {
                      staticStyle: { "margin-left": "10px" },
                      model: {
                        value: item.enable,
                        callback: function($$v) {
                          _vm.$set(item, "enable", $$v)
                        },
                        expression: "item.enable"
                      }
                    })
                  ],
                  1
                ),
                !_vm.versionCheck
                  ? _c(
                      "el-col",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "margin-top": "10px",
                          "margin-bottom": "10px"
                        },
                        attrs: { span: 2 }
                      },
                      [
                        _c("img", {
                          staticClass: "icon-contact",
                          attrs: { src: "/assets/icons/" + item.icon + ".png" },
                          on: { error: _vm.onImageError }
                        })
                      ]
                    )
                  : _vm._e(),
                _c("el-col", { attrs: { span: 10 } }, [
                  _vm._v("\n          " + _vm._s(item.title) + "\n        ")
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.getType(item.id)) + "\n        "
                  )
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  item.enable && item.id != 8
                    ? _c(
                        "span",
                        {
                          staticClass: "column-drag-handle",
                          staticStyle: { float: "right", padding: "0 20px" }
                        },
                        [_vm._v("☰")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.onClickEditWidget(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-edit" })]
                  )
                ])
              ],
              1
            )
          ])
        }),
        1
      ),
      _c("WidgetDialog", {
        ref: "widgetDialog",
        attrs: { visible: _vm.newDialogVisible, widget: _vm.activeWidget },
        on: {
          saveWidget: _vm.saveWidget,
          cancelWidgetDialog: function($event) {
            _vm.newDialogVisible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }